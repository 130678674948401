import script from "./RangeDatePicker.vue?vue&type=script&lang=ts&setup=true"
export * from "./RangeDatePicker.vue?vue&type=script&lang=ts&setup=true"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QPopupProxy,QDate});
