import {
  feature, featureCollection, MultiPolygon, polygon, Polygon, lineString,
} from '@turf/helpers';
import { IndicatorType } from '@/dialogs/AssayDialog/constants/optionsIndicators';
import bbox from '@turf/bbox';
import area from '@turf/area';
import pointGrid from '@turf/point-grid';
import { formatGeoJSONReadFeatures, getLineByPolygon } from '@/helpers/map';
import booleanIntersects from '@turf/boolean-intersects';
import VectorSource from 'ol/source/Vector';
import length from '@turf/length';
import along from '@turf/along';
import { Feature } from 'ol';
import { fromLonLat } from 'ol/proj';
import useDrawAutoHelpers from '@/dialogs/AssayDialog/hooks/useDrawAutoHelpers';
import tin from '@turf/tin';
import pointsWithinPolygon from '@turf/points-within-polygon';
import planepoint from '@turf/planepoint';
import * as d3 from 'd3';
import * as MarchingSquaresJS from 'marchingsquares/dist/marchingsquares-isobands.min';
import { Coordinate } from 'ol/coordinate';
import ElementaryAreas from '@/dialogs/AssayDialog/types/ElementaryAreas';
import Analyses from '@/dialogs/AssayDialog/types/Analyses';

const rotateArray = (arr) => {
  const nArr = []; // новый перевёрнутый массив

  const nRows = arr[0].length; // количество новых строк

  const nCols = arr.length; // количество новых столбцов

  for (let x = 0; x < nRows; x += 1) {
    const rowArr = []; // это элемент из нового массива
    let y = (nCols - 1);
    let z = 0;
    while (y >= 0) {
      rowArr[z] = arr[y][x];
      y -= 1;
      z += 1;
    }

    nArr[x] = rowArr;
  }

  return nArr;
};

export default function useIsoBandsAssay() {
  const { reducePolygon } = useDrawAutoHelpers();

  const getFeatureScheme = (
    elementaryAreas: ElementaryAreas[],
    analyses: Analyses,
    indicator: IndicatorType,
  ) => {
    const points = [];
    const elementaryAreaFeatures = [];

    const attributesAnalysisByIdElementaryArea = analyses?.data
      ? analyses.data.reduce((result, { elementaryAreaId, attributes }) => {
        // eslint-disable-next-line no-param-reassign
        result[elementaryAreaId] = attributes;
        return result;
      }, []) : [];

    elementaryAreas.forEach(({ id, geometry, point }) => {
      const indicatorAssay = attributesAnalysisByIdElementaryArea[id]?.[indicator];
      if (indicatorAssay != null) {
        const pointFeature = feature(
          point,
          { assay: indicatorAssay || null },
        );

        const elementaryArea = feature(
          geometry,
          { point },
        );

        points.push(pointFeature);
        elementaryAreaFeatures.push(elementaryArea);
      }
    });
    return { points, elementaryAreaFeatures };
  };

  const fillingAssay = (unflatList, isTopBottomFilling = false) => {
    unflatList.forEach((pointsList) => {
      const lengthPaintedPoints = pointsList.filter((item) => item.properties.assay == null).length;

      if (!isTopBottomFilling && lengthPaintedPoints > pointsList.length * 0.6) {
        return;
      }

      // TODO разделить на облости вставки
      const listSort = [{ status: false, items: [] }];

      pointsList.forEach((item) => {
        const lastSort = listSort[listSort.length - 1];
        if (lastSort.status === !!item.properties.assay) {
          lastSort.items.push(item);
        } else {
          listSort.push({ status: !!item.properties.assay, items: [item] });
        }
      });

      listSort.forEach((itemSort, index, array) => {
        if (!itemSort.status && array.length > 1) {
          const leftArray = array[index - 1];
          const rightArray = array[index + 1];
          if (
            leftArray && leftArray.status && leftArray.items.length > 1
            && rightArray && rightArray.status && rightArray.items.length > 1
          ) {
            let lastLeftItem = leftArray.items[leftArray.items.length - 1];
            const leftK = lastLeftItem.properties.assay
              - leftArray.items[leftArray.items.length - 2].properties.assay;

            let lastRightItem = rightArray.items[0];
            const rightK = lastRightItem.properties.assay - rightArray.items[1].properties.assay;

            let indexLeftItemSort = 0;
            let indexRightItemSort = itemSort.items.length - 1;

            while (indexLeftItemSort <= indexRightItemSort) {
              // левоя сторона блока
              const newAssayLeft = lastLeftItem.properties.assay + leftK;
              // eslint-disable-next-line no-param-reassign
              itemSort.items[indexLeftItemSort].properties.assay = newAssayLeft < 0
                ? 0.01 : newAssayLeft;
              lastLeftItem = itemSort.items[indexLeftItemSort];
              indexLeftItemSort += 1;

              // правоя сторона блока
              const newAssayRight = lastRightItem.properties.assay + rightK;
              // eslint-disable-next-line no-param-reassign
              itemSort.items[indexRightItemSort].properties.assay = newAssayRight < 0
                ? 0.01 : newAssayRight;
              lastRightItem = itemSort.items[indexRightItemSort];
              indexRightItemSort -= 1;
            }
          } else if (rightArray && rightArray.status && rightArray.items.length > 1) {
            // установка assay по правой стороне

            let lastRightItem = rightArray.items[0];
            const rightK = lastRightItem.properties.assay - rightArray.items[1].properties.assay;
            let indexRightItemSort = itemSort.items.length - 1;

            while (indexRightItemSort >= 0) {
              const newAssay = lastRightItem.properties.assay + rightK;
              // eslint-disable-next-line no-param-reassign
              itemSort.items[indexRightItemSort].properties.assay = newAssay < 0 ? 0.01 : newAssay;
              lastRightItem = itemSort.items[indexRightItemSort];
              indexRightItemSort -= 1;
            }
          } else if (leftArray && leftArray.status && leftArray.items.length > 1) {
            // установка assay по левой стороне
            let lastLeftItem = leftArray.items[leftArray.items.length - 1];
            const leftK = lastLeftItem.properties.assay
              - leftArray.items[leftArray.items.length - 2].properties.assay;

            let indexLeftItemSort = 0;

            while (indexLeftItemSort < itemSort.items.length) {
              const newAssay = lastLeftItem.properties.assay + leftK;
              // eslint-disable-next-line no-param-reassign
              itemSort.items[indexLeftItemSort].properties.assay = newAssay < 0 ? 0.01 : newAssay;
              lastLeftItem = itemSort.items[indexLeftItemSort];
              indexLeftItemSort += 1;
            }
          }
        }
      });
    });
  };

  const getLineBorderPointElementaryAreas = (
    field: Polygon | MultiPolygon,
    elementaryAreas: Feature[],
  ) => {
    // TODO расчитать только для полигон, в дальнейшем разбить на мультиполигоны
    const fieldReducePolygon = reducePolygon(feature(field));
    let lineBorderField = getLineByPolygon(fieldReducePolygon);

    if (lineBorderField.type === 'FeatureCollection') {
      // eslint-disable-next-line prefer-destructuring
      lineBorderField = lineBorderField.features[0];
    }

    const lineBorderFieldStringLine = lineBorderField.geometry.type === 'MultiLineString'
      ? lineString(lineBorderField.geometry.coordinates[0]) : lineBorderField;

    const borderFromElementaryAreas = elementaryAreas
      // @ts-ignore
      .filter((elementaryArea) => (booleanIntersects(elementaryArea, lineBorderField)));
    const mapSoursFind = new VectorSource({
      wrapX: false,
    });

    mapSoursFind.addFeatures(formatGeoJSONReadFeatures(
      // @ts-ignore
      featureCollection(borderFromElementaryAreas),
    ));

    const lengthLine = length(lineBorderFieldStringLine);
    let distance = 0.001;
    const listPoints = [];
    while (lengthLine > distance) {
      // @ts-ignore
      const pointOnLine = along(lineBorderFieldStringLine, distance);
      listPoints.push(pointOnLine);
      distance += 0.005;
    }

    const sortElementaryAreas = [];
    let activePolygon: Feature = null;
    listPoints.forEach((point) => {
      const coordinates = fromLonLat(point.geometry.coordinates);

      if (activePolygon && activePolygon.getGeometry().intersectsCoordinate(coordinates)) {
        return;
      }
      const newPolygons = mapSoursFind.getFeaturesAtCoordinate(coordinates);
      if (newPolygons.length === 1) {
        activePolygon = newPolygons[0] as Feature;
        sortElementaryAreas.push(activePolygon);
      }
    });
    const lineBorderPointsAssay = polygon([
      [
        ...sortElementaryAreas.map((item) => item.get('point').coordinates),
        sortElementaryAreas[0].get('point').coordinates,
      ],
    ]);
    return reducePolygon(lineBorderPointsAssay);
  };

  const getSvgIsoBands = (
    matrix: number[][],
    breaks: number[],
    colorBands: string[],
    width: number,
    height: number,
  ) => {
    const xs = d3.range(0, matrix[0].length);
    const ys = d3.range(0, matrix.length);

    const xScale = d3.scaleLinear()
      .range([0, width])
      .domain([Math.min.apply(null, xs), Math.max.apply(null, xs)]);

    const yScale = d3.scaleLinear()
      .range([0, height])
      .domain([Math.min.apply(null, ys), Math.max.apply(null, ys)]);

    const colours = d3.scaleLinear()
      .domain(breaks)
      // @ts-ignore
      .range(colorBands);

    const isoBands = [];
    for (let i = 1; i < breaks.length; i += 1) {
      const lowerBand = breaks[i - 1];
      const upperBand = breaks[i];

      const band = MarchingSquaresJS.isoBands(matrix, lowerBand, upperBand - lowerBand);
      isoBands.push({ coords: band, level: i, val: breaks[i] });
    }

    const newDiv = document.createElement('div');
    const svg = d3.select(newDiv)
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    svg.selectAll('path')
      .data(isoBands)
      .enter()
      .append('path')
      .style('fill', (d) => colours(d.val))
      .style('stroke', (d) => colours(d.val))
      .style('opacity', 1.0)
      .attr('d', (d) => {
        let p = '';
        d.coords.forEach((aa) => {
          p += `${(d3.line()
            .x((dat) => xScale(dat[0]))
            .y((dat) => yScale(dat[1]))
            .curve(d3.curveCatmullRomClosed.alpha(1))
          )(aa)}Z`;
        });
        return p;
      });
    return svg;
  };

  const getIsoBands = (
    field: Polygon | MultiPolygon,
    elementaryAreas: ElementaryAreas[],
    analyses: Analyses,
    indicator: IndicatorType,
    breaks: number[],
    colorBreaks: string[],
    width: number,
    height: number,
    fieldCoordinatesCanvas: Coordinate[][],
  ) => new Promise<string>((resolve) => {
    const boxField = bbox(field);
    const areaField = area(field) / 10000;
    const dotFrequency = areaField >= 100 ? 0.04 : (areaField * 0.04) / 100;
    const pointGridNew = pointGrid<{assay: number}>(
      boxField,
      dotFrequency,
    );

    const { elementaryAreaFeatures, points } = getFeatureScheme(
      elementaryAreas,
      analyses,
      indicator,
    );
    const lineBorderPointElementaryAreas = getLineBorderPointElementaryAreas(
      field,
      elementaryAreaFeatures,
    );

    const tinPolygons = tin(featureCollection(points), 'assay').features
      .filter((item) => booleanIntersects(item, lineBorderPointElementaryAreas));

    tinPolygons.forEach((polygon) => {
      const pointGridPolygon = pointsWithinPolygon(pointGridNew, polygon);
      pointGridPolygon.features.forEach((point) => {
        // eslint-disable-next-line no-param-reassign
        point.properties.assay = planepoint(point, polygon);
      });
    });

    const heightLength = pointGridNew.features
      .findIndex((item) => item.geometry.coordinates[0]
          !== pointGridNew.features[0].geometry.coordinates[0]);

    // const widthLength = pointGridNew.features.length / heightLength;

    const unflatList = Array.from(new Array(heightLength), () => []);
    pointGridNew.features.forEach((item, index) => {
      unflatList[heightLength - 1 - (index % heightLength)].push(item);
    });

    fillingAssay(unflatList);
    fillingAssay(rotateArray(unflatList), true);
    fillingAssay(unflatList, true);

    const matrixData = unflatList.map((items) => items.map(({ properties: { assay } }) => assay));

    const svg = getSvgIsoBands(matrixData, breaks, colorBreaks, width, height);
    const s = new XMLSerializer().serializeToString(svg.node());
    const encodedData = window.btoa(s);
    const base64Svg = `data:image/svg+xml;base64,${encodedData}`;

    const mapCanvas = document.createElement('canvas');
    mapCanvas.width = width;
    mapCanvas.height = height;
    const ctx = mapCanvas.getContext('2d');

    const svgImage = new Image();
    svgImage.onload = () => {
      const polygon = fieldCoordinatesCanvas[0];
      ctx.beginPath();
      ctx.moveTo(polygon[0][0], polygon[0][1]);
      polygon.forEach(([x, y]) => ctx.lineTo(x, y));
      ctx.clip();
      ctx.closePath();
      // fieldCoordinatesCanvas.forEach((polygon) => {
      //   ctx.beginPath();
      //   ctx.moveTo(polygon[0][0], polygon[0][1]);
      //   polygon.forEach(([x, y]) => ctx.lineTo(x, y));
      //   ctx.clip();
      //   ctx.closePath();
      // });
      ctx.drawImage(svgImage, 0, 0);

      resolve(mapCanvas.toDataURL('image/png'));
    };
    svgImage.src = base64Svg;
  });

  return { getIsoBands };
}
